<nb-layout windowMode>
  <nb-layout-header fixed>
    <app-header></app-header>
  </nb-layout-header>

  <nb-sidebar
    *ngIf="showSidebar()"
    class="menu-sidebar"
    tag="menu-sidebar"
    responsive
  >
    <ng-content select="nb-menu"></ng-content>
  </nb-sidebar>

  <nb-layout-column class="nb-layout-column-custom">
    <ng-content select="router-outlet"></ng-content>
  </nb-layout-column>
</nb-layout>
