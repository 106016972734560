import { Component } from '@angular/core';
import { SidebarService } from '../../../shared/services/sidebar.service';

@Component({
  selector: 'app-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
})
export class OneColumnLayoutComponent {
  constructor(private sidebarService: SidebarService) {}

  shouldShowSidebarAndHeader(): boolean {
    return this.sidebarService.shouldShowSidebarAndHeader();
  }

  shouldOnlyShowHeader(): boolean {
    return this.sidebarService.shouldShowOnlyHeader();
  }

  showSidebar(): boolean {
    return this.shouldShowSidebarAndHeader() && !this.shouldOnlyShowHeader();
  }
}
