import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LayoutService } from '../../../@core/utils';
import { ProfileService } from '../../../shared/services/profile.service';
import { SidebarService } from '../../../shared/services/sidebar.service';
import { createS3Url } from '../../../shared/utils';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userFullName: string;
  imageUrl: string;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
  ];

  currentTheme = 'default';

  userMenu: NbMenuItem[] = [
    { title: 'Profile', icon: 'person-outline' },
    { title: 'Log out', icon: 'log-out' },
  ];

  constructor(
    private nbSidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private profileService: ProfileService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private nbMenuService: NbMenuService,
    private router: Router,
    private sidebarService: SidebarService,
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.menuService();
    this.profileService
      .getUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          this.user = res.data.user;
          this.imageUrl = createS3Url(this.user.accountImageUrl);

          const firstName = this.user.firstName;
          const lastName = this.user.lastName;

          const userNamesExist = firstName && lastName;

          this.userFullName = userNamesExist
            ? `${this.user.firstName} ${this.user.lastName}`
            : undefined;
        },
        (err) => {
          const errMessage = err.statusText;

          if (errMessage === 'Unknown Error') {
            const username = null;
            this.profileService.logout(username);
          }
        },
      );

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl),
      );

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private shouldShowOnlyHeader(): boolean {
    return this.sidebarService.shouldShowOnlyHeader();
  }

  hideProfile(): boolean {
    return this.shouldShowOnlyHeader();
  }

  showToggle(): boolean {
    return !this.shouldShowOnlyHeader();
  }

  onReturnBack(): void {
    this.router.navigate(['/forms']);
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.nbSidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  onBuyPaid(): void {
    this.router.navigate(['/subscription']);
  }

  navigateHome() {
    this.nbMenuService.navigateHome();
    return false;
  }

  private menuService(): void {
    this.nbMenuService.onItemClick().subscribe((data) => {
      const title = data.item.title;

      if (title === 'Profile') {
        this.router.navigate(['/profile']);
      }

      if (title === 'Log out') {
        const username = this.user?.username ?? null;

        this.profileService.logout(username);
      }

      return;
    });
  }
}
