import { environment } from '../../environments/environment';

export const createS3Url = (key: string) => {
  if (!key) return null;

  const s3Url = `${environment.s3BaseUrl}/${key}`;
  return s3Url;
};

export const buildRandomFormCardImage = () => {
  const randomNumber =
    randomImageURLs[Math.floor(Math.random() * randomImageURLs.length)];
  return randomNumber.key;
};

// todo
// deploy these images to s3
const randomImageURLs = [
  {
    id: 0,
    key: 'images/default-form-card-images/calm.jpeg',
  },
  {
    id: 1,
    key: 'images/default-form-card-images/landscape.jpeg',
  },
  {
    id: 2,
    key: 'images/default-form-card-images/stones.jpeg',
  },
  {
    id: 3,
    key: 'images/default-form-card-images/sky.jpeg',
  },
  {
    id: 4,
    key: 'images/default-form-card-images/waves.jpeg',
  },
  {
    id: 5,
    key: 'images/default-form-card-images/sea.jpeg',
  },
  {
    id: 6,
    key: 'images/default-form-card-images/forest.jpeg',
  },
  {
    id: 7,
    key: 'images/default-form-card-images/flowers.jpeg',
  },
  {
    id: 8,
    key: 'images/default-form-card-images/mountains.jpeg',
  },
  {
    id: 9,
    key: 'images/default-form-card-images/clouds.jpeg',
  },
];

export const defaultColors = {
  navigationButtonBackgroundColor: '#ffb238',
  navigationButtonTextColor: '#ffffff',
  titleTextColor: '#000000',
  subtitleTextColor: '#000000',
};
