import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  public getUserProfile(): Observable<any> {
    const path = `${environment.baseUrl}/user/account`;
    return this.http.get<any>(path, { withCredentials: true });
  }

  public deleteUser(id: string): Observable<any> {
    const path = `${environment.baseUrl}/user/${id}`;
    return this.http.delete<any>(path, { withCredentials: true });
  }

  public uploadfileToAWSS3(
    fileUploadUrl: string,
    contentType: string,
    file: File,
  ): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': contentType });
    const reqObj = new HttpRequest('PUT', fileUploadUrl, file, {
      headers: headers,
    });
    return this.http.request(reqObj);
  }

  public logout(username: string | null): void {
    const path = `${environment.baseUrl}/user/auth/signout`;
    this.http
      .post<any>(path, { username }, { withCredentials: true })
      .subscribe(() => {
        window.location.href = 'https://auth.formslides.com';
      });
  }
}
