<div class="header-container">
  <div class="logo-container">
    <a
      *ngIf="showToggle()"
      (click)="toggleSidebar()"
      href="#"
      class="sidebar-toggle"
    >
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>

    <div class="form-logo-box">
      <img
        class="form-logo"
        (click)="navigateHome()"
        src="assets/images/logo2.svg"
      />
      <div class="poweredby-text">Form Slides</div>
    </div>
  </div>
</div>

<div class="header-container">
  <ng-container *ngIf="hideProfile(); else showProfile">
    <ng-container *ngIf="user?.plan === 'free'">
      <button
        class="buy-paid-button"
        mat-raised-button
        color="primary"
        (click)="onBuyPaid()"
      >
        Unlock all pro features
      </button>
    </ng-container>

    <div class="return-back" (click)="onReturnBack()">
      <mat-icon class="return-back-icon">keyboard_backspace</mat-icon>
      <div class="return-back-text">Back to forms</div>
    </div>
  </ng-container>

  <ng-template #showProfile>
    <nb-actions size="small">
      <nb-action class="user-action">
        <nb-user
          color="#cccccc"
          size="large"
          [nbContextMenu]="userMenu"
          [onlyPicture]="userPictureOnly"
          [name]="userFullName"
          [picture]="imageUrl"
          [title]="user?.email"
          nbContextMenuTrigger="hover"
        >
        </nb-user>
      </nb-action>
    </nb-actions>
  </ng-template>
</div>
