// sidebar.service.ts
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private showSidebarAndHeader = true;
  private showOnlyHeader = false;
  private showOnlySidebar = false;

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showSidebarAndHeader = !event.url.includes('/responses/');

        this.showOnlyHeader =
          event.url.includes('/form/edit/') || event.url.includes('/form/new');
      }
    });
  }

  shouldShowSidebarAndHeader(): boolean {
    return this.showSidebarAndHeader;
  }

  shouldShowOnlyHeader(): boolean {
    return this.showOnlyHeader;
  }
}
